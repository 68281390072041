import React from 'react';
import { colors, viewports } from '../../style-vars';

const EventStyles = () => (
  <style jsx global>{`
    .event-special {
      background: #07204d;
      padding: 103px 158px;
      margin-bottom: 200px;
      position: relative;
    }
    .event-title {
      font-size: 42px;
      color: ${colors.white};
      font-weight: 800;
    }
    .left-title {
      font-size: 24px;
      color: ${colors.white};
      color: #fff;
    }
    .left-sub {
      font-size: 16px;
      color: #e4e6e9;
    }
    .event-date {
      font-size: 18px;
      color: ${colors.white};
      font-weight: 800;
    }
    .event-blue {
      color: #1769ff;
      font-size: 18px;
      font-weight: 800;
    }
    .event-local {
      font-size: 16px;
      color: #d6dadf;
    }
    .bottom-image {
      width: 100%;
      margin: 0 auto;
      transform: translateY(50%);
    }
    .col-a,
    .col-b {
      border-bottom: 1px solid #013f99;
      paddingbottom: 12px;
    }
    @media ${viewports.mdOrSmaller} {
      .col-a {
        border-bottom: none;
      }
    }
    @media ${viewports.smOrSmaller} {
      .event-special {
        background: #07204d;
        padding: 32px 16px;
        margin-bottom: 100px;
        position: relative;
      }
    }
  `}</style>
);

export default EventStyles;
