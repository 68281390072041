import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "antd";
import EventStyles from "./events";
import CtaPrimary from "../Buttons/primary-cta";
import Image from "../ImageQuerys/RandomImages";

const SpecialEvents = (props) => {
  const { events, link, title, image } = props;
  return (
    <section className="event-special">
      <Row>
        <Col lg={16} sm={24}>
          <h1 className="event-title">
            {title}
            <span className="blue_dot">.</span>
          </h1>
        </Col>
        <Col lg={8} sm={24}>
          {/* eslint-disable-next-line react/prop-types */}
          <CtaPrimary ctaTitle={link.ctaTitle} target={link.ctaTarget} />
        </Col>
      </Row>
      <Row style={{ marginTop: 60 }}>
        {events.map((show) => (
          <>
            <Col lg={16} sm={24} className="col-a">
              <h3 style={{ marginTop: 10 }} className="left-title">
                {show.eventTitle}
                <span className="blue_dot">.</span>
              </h3>
              <h4 className="left-sub">{show.eventSub}</h4>
            </Col>
            <Col lg={8} sm={24} className="col-b">
              <h3 style={{ marginTop: 10 }} className="event-date">
                {show.eventDate}{" "}
                <span className="event-blue">{show.eventTime}</span>
              </h3>
              <h4 className="event-local">{show.eventLocal}</h4>
            </Col>
          </>
        ))}
      </Row>
      <Image className="bottom-image" imageName={image} />
      <EventStyles />
    </section>
  );
};
SpecialEvents.propTypes = {
  events: PropTypes.arrayOf(PropTypes.any),
  image: PropTypes.string,
  link: PropTypes.string,
  title: PropTypes.string,
};
SpecialEvents.defaultProps = {
  events: [],
  image: "",
  link: "",
  title: "",
};
export default SpecialEvents;
